import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    errors: {},
    setResponse: {},
    loading: '',
    hovered: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.data = action.payload;
        },
        loginError: (state, action) => {
            state.errors = action.payload;
        },
        registrationSuccess: (state, action) => {
            state.setResponse = action.payload;
        },
        registrationError: (state, action) => {
            state.setResponse = action.payload;
        },
        authLoading: (state, action) => {
            state.loading = action.payload;
        },
        setHover: (state, action) => {
            state.hovered = action.payload;
        }
    },
});

export const { loginSuccess, loginError, registrationSuccess, registrationError, authLoading, setHover } = authSlice.actions;

export default authSlice.reducer;