// Provides validation scripts for forms
import * as yup from 'yup';

/**
 * Validation for user fields
 */
var passwordValidations = yup.string().min(8, "minimun 8 character required").matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>+\-_=~`[\]\\/]).{8,}$/,
    'Password must include uppercase , lowercase , number and special character.'
).required('Password is required');

var confirmPasswordValidations = yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('confirm your password');

var emailValidations = yup.string().email('Email is invalid').required('Email is required');

var firstNameValidations = yup.string().matches(/^[A-Za-z ]+$/, 'Please enter only alphabets').trim().min(3, "minimun 3 character required")
    .max(35, "35 max character allowed").matches(/^[a-zA-Z]+$/, 'First name is invalid').required('First name is required');

var lastNameValidations = yup.string().matches(/^[A-Za-z ]+$/, 'Please enter only alphabets').trim().min(3, "minimun 3 character required");


const userValidation = {
    initialValues: {
        email: '',
        first_name: '',
        last_name: ''
    },
    schema: yup.object().shape({
        email: emailValidations,
        first_name: firstNameValidations,
        last_name: lastNameValidations
    })
}

const addressValidation = {
    initialValues: {
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        phone_number: ''
    },
    schema: yup.object().shape({
        line_1: yup.string().required('Address line 1 is required').trim(),
        line_2: yup.string().trim(),
        city: yup.string().min(3, "minimun 3 character required").max(35, "35 max character allowed").matches(/^[A-Za-z ]+$/, 'Please enter only alphabets').trim().required('City is required'),
        state: yup.string().min(3, "minimun 3 character required").max(35, "35 max character allowed").matches(/^[A-Za-z ]+$/, 'Please enter only alphabets').trim().required('State is required'),
        country: yup.string().matches(/^[A-Za-z ]+$/, 'Please enter only alphabets').trim().required('Country is required'),
        zip_code: yup.string().matches(/^\d+$/, 'Zip code must be a positive number').trim().required('Zip code is required'),
        phone_number: yup.string().matches(/^[+]?[0-9]{7,15}$/, 'phone number must start with + and between 7 to 15 numeric values').trim().required('Phone number is required')
    })
}

const organizationValidation = {
    initialValues: {
        name: ''
    },
    schema: yup.object().shape({
        name: yup.string().min(3, "minimun 3 character required").max(35, "35 max character allowed").matches(/^[A-Za-z ]+$/, 'Please enter only alphabets').trim().required('Organization name is required')
    })
}

const departmentValidation = {
    initialValues: {
        name: '',
        description: ''
    },
    schema: yup.object().shape({
        name: yup.string().min(3, "minimun 3 character required").max(35, "35 max character allowed").matches(/^[A-Za-z,()\s]*$/, 'name can contain only letters, comma, (), spaces').required('department name is required')
    })
}

const designation = {
    initialValues: {
        department_id: '',
        name: '',
    },
    schema: yup.object().shape({
        department_id: yup.string().required('department type is required'),
        name: yup.string().min(3, "minimun 3 character required").max(35, "35 max character allowed").matches(/^[A-Za-z,.()\s]*$/, 'name can contain only letters, comma,., (), spaces').required('designation name is required'),
    })
}

const degreeSchema = yup.lazy((value) => {
    if (value && (value.name || value.level || value.institution || value.graduation_date)) {
        return yup.object().shape({
            name: yup.string().required('Degree name is required'),
            level: yup.string().required('Degree level is required'),
            institution: yup.string().required('Institute is required'),
            graduation_date: yup.date().required('Graduation date is required')
        });
    }
    return yup.object().shape({
        name: yup.string(),
        level: yup.string(),
        institution: yup.string(),
        graduation_date: yup.date()
    });
});


const durationTime = /^(\d+)m$/;

const userProfile = {
    initialValues: {
        type: '',
        designation_id: '',
        department_id: '',
        date_of_joining: '',
        receptionist_id: '',
        fee: '',
        speciality_id: '',
        appointment_duration: '',
        advance_booking_days: '',
        admin_managed_schedule: '',
        payrollType: 'salary',
        salary: '',
        monthly_share: ''
    },
    schema: yup.object().shape({
        designation_id: yup.string().required('designation is required'),
        department_id: yup.string().required('department is required'),
        date_of_joining: yup.date().required('joining date is required'),
        receptionist_id: yup.string().required('receptionist is required'),
        fee: yup.number().positive('fee must be a positive number').required('fee is required'),
        appointment_duration: yup.string().matches(durationTime, 'Invalid duration format. Use "Xm" where X is a positive integer.'),
        advance_booking_days: yup.number().positive('advance days must be in positive number'),
        payrollType: yup.string().required('Please select a payroll'),
        salary: yup.lazy((value, { parent }) => {
            if (parent.payrollType === 'salary') {
                return yup.string().required('salary is required');
            }
            return yup.string();
        }),
        monthly_share: yup.lazy((value, { parent }) => {
            if (parent.payrollType === 'monthly_share') {
                return yup.string().required('monthly share is required');
            }
            return yup.string();
        })
    })
}

const userProfileReceptionist = {
    schema: yup.object().shape({
        designation_id: yup.string().required('Designation is required'),
        department_id: yup.string().required('Department is required'),
        date_of_joining: yup.date().required('Joining date is required'),
        admin_managed_schedule: yup.string().required('schedule manage is required'),
        salary: yup.string().required('Salary is required')
    })
}

const userPatient = {
    schema: yup.object().shape({
        email: emailValidations,
        first_name: firstNameValidations,
        last_name: lastNameValidations,
        phone_number: yup.string().matches(/^[+]?[0-9]{7,15}$/, 'phone number must have + and between 7 to 15 digits').required('phone number is required'),
        date_of_birth: yup.string().required('date of birth is required'),
        gender: yup.string().required('gender is required')
    })
}

const userProfileEmail = {
    initialValues: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        date_of_birth: '',
        gender: '',
        cnic: ''
    },
    schema: yup.object().shape({
        email: emailValidations,
        first_name: firstNameValidations,
        last_name: lastNameValidations,
        phone_number: yup.string().matches(/^[+]?[0-9]{7,15}$/, 'phone number must have + and between 7 to 15 digits').required('phone number is required'),
        gender: yup.string().required('gender is required')
    })
}

const certificationValidation = {
    initialValues: {
        name: '',
        issuing_organization: '',
        issue_date: '',
        expiration_date: ''
    },
    schema: yup.object().shape({
        name: yup.string().required('certificate name is required'),
        issuing_organization: yup.string().required('certificate organization name required'),
        issue_date: yup.date().required('issue date is required'),
        expiration_date: yup.date().required('expire date is required')
    })
}

const userProfileValidation = {
    initialValues: {
        address: addressValidation.initialValues,
        user: userProfileEmail.initialValues,
        profile: userProfile.initialValues,
        degrees: [{
            degree: {
                name: '',
                level: '',
                institution: '',
                graduation_date: ''
            }
        }],
        certification: certificationValidation.initialValues,
    },
    schema: yup.object().shape({
        address: yup.lazy((value) => {
            if (
                value &&
                (value.line_1 || value.line_2 || value.city || value.state || value.country || value.zip_code || value.phone_number)
            ) {
                return addressValidation.schema;
            }
            else {
                return yup.object().shape({
                    line_1: yup.string(),
                    line_2: yup.string(),
                    city: yup.string(),
                    state: yup.string(),
                    country: yup.string(),
                    zip_code: yup.string(),
                    phone_number: yup.string()
                });
            }
        }),
        user: userProfileEmail.schema,
        profile: yup.lazy((value) => {
            if (
                value.type === 'Doctor'

            ) {
                return userProfile.schema;
            }
            else {
                return userProfileReceptionist.schema;
            }
        }),
        degrees: yup.array().of(
            yup.object().shape({
                degree: degreeSchema
            })
        ),
        certification: yup.lazy((value) => {
            if (
                value &&
                (value.name || value.issuing_organization || value.issue_date || value.expiration_date)
            ) {
                return certificationValidation.schema;
            }
            else {
                return yup.object().shape({
                    name: yup.string(),
                    issuing_organization: yup.string(),
                    issue_date: yup.string(),
                    expiration_date: yup.string()
                });
            }
        })
    })
}


const patientProfileValidation = {
    initialValues: {
        address: addressValidation.initialValues,
        user: userProfileEmail.initialValues,
    },
    schema: yup.object().shape({
        address: yup.lazy((value) => {
            if (
                value &&
                (value.line_1 || value.line_2 || value.city || value.state || value.country || value.zip_code || value.phone_number)
            ) {
                return addressValidation.schema;
            }
            else {
                return yup.object().shape({
                    line_1: yup.string(),
                    line_2: yup.string(),
                    city: yup.string(),
                    state: yup.string(),
                    country: yup.string(),
                    zip_code: yup.string(),
                    phone_number: yup.string()
                });
            }
        }),
        user: userPatient.schema
    })
}

const offDays = {
    initialValues: {
        date: '',
        til_date: '',
        move_appointments_to: '',
        custom_email_body: ''
    },
    schema: yup.object().shape({
        date: yup.date().required('start date is required'),
    })
}

const dayOffValidation = {
    initialValues: {
        offday: offDays.initialValues
    },
    schema: yup.object().shape({
        offday: offDays.schema
    })
}

const degreeValidation = {
    initialValues: {
        name: '',
        level: '',
        institution: '',
        graduation_date: ''
    },
    schema: yup.lazy((value) => {
        if (value && (value.name || value.level || value.institution || value.graduation_date)) {
            return yup.object().shape({
                name: yup.string().required('Degree name is required'),
                level: yup.string().required('Degree level is required'),
                institution: yup.string().required('Institute is required'),
                graduation_date: yup.date().required('Graduation date is required')
            });
        }
        return yup.object().shape({
            name: yup.string(),
            level: yup.string(),
            institution: yup.string(),
            graduation_date: yup.date()
        });
    })
}

const appointment = {
    initialValues: {
        start_time: '',
        end_time: '',
        doctor_id: '',
        patient_id: '',
        department_id: '',
        title: '',
        description: '',
        date: '',
        gateway_type: 'cash',
        services: [],
        amount_paid: '',
        discount: '',
        generate_pdf: ''
    },
    schema: yup.object().shape({
        start_time: yup.string().required('Select Time slot'),
        doctor_id: yup.string().required('Doctor name required'),
        patient_id: yup.string().required('Patient name required'),
        department_id: yup.string().required('Department name required'),
        title: yup.string().required('Title required'),
        date: yup.string().required('Date required'),
        gateway_type: yup.string().required('Payment gateway required'),
        amount_paid: yup.number(),
        discount: yup.number()
    })
}

const appointmentValidation = {
    initialValues: {
        appointment: appointment.initialValues
    },
    schema: yup.object().shape({
        appointment: appointment.schema
    })
}

/**
 * Signup form validation
 */

const signUpValidation = {
    initialValues: {
        user: userValidation.initialValues,
        address: addressValidation.initialValues,
        organization: organizationValidation.initialValues
    },
    schema: yup.object().shape({
        user: userValidation.schema,
        address: addressValidation.schema,
        organization: organizationValidation.schema
    })
}

/**
 * SignIn form validation
 */
const signInValidation = {
    initialValues: {
        email: '',
        password: '',
        organization: {
            name: ''
        }
    },
    schema: yup.object().shape({
        email: emailValidations,
        password: yup.string().required('Password is required'),
        organization: yup.object().shape({
            name: yup.string().required('Organization name is required')
        })
    })
}

const departmentAdditionValidation = {
    initialValues: {
        department: departmentValidation.initialValues
    },
    schema: yup.object().shape({
        department: departmentValidation.schema
    })
}

const designationValidation = {
    initialValues: {
        designation: designation.initialValues
    },
    schema: yup.object().shape({
        designation: designation.schema
    })
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const forgotInitialValues = {
    initialValues: {
        email: '',
    },
    schema: yup.object().shape({
        email: yup.string().matches(emailRegex, 'Email is invalid').required('Email is required'),
    })
}

const updatePasswordInitialValues = {
    initialValues: {
        password: '',
        password_confirmation: ''
    },
    schema: yup.object().shape({
        password: passwordValidations,
        password_confirmation: confirmPasswordValidations
    })
}

const verifyEmailPassword = {
    initialValues: {
        password: '',
        password_confirmation: ''
    },
    schema: yup.object().shape({
        password: passwordValidations,
        password_confirmation: confirmPasswordValidations
    })
}

const timingValidation = {
    initialValues: {
        day_of_week: '',
        start_time: '',
        end_time: ''
    },
    schema: yup.object().shape({
        day_of_week: yup.string(),
        start_time: yup.string().required('Start time is required'),
        end_time: yup.string().required('End time is required'),
    })
}

const timingForm = {
    initialValues: {
        availability: timingValidation.initialValues
    },
    schema: yup.object().shape({
        availability: timingValidation.schema
    })
}

const vitalSign = {
    initialValues: {
        blood_pressure: '',
        pulse_rate: '',
        temperature: '',
        weight: '',
        height: '',
        oxygen_saturation: '',
        consciousness_level: '',
        pain_level: ''
    },
    schema: yup.object().shape({
        blood_pressure: yup.string().matches(/^\d{2,3}\/\d{2,3}\s?mmHg$/, 'Invalid blood pressure format'),
        pulse_rate: yup.number()
            .integer('Please enter only integer')
            .positive('Please enter a positive number')
            .max(200, 'Maximum value is 200'),
        temperature: yup.number().min(70, 'Value must be 70 or greater')
            .max(110, 'Value must be less than or equal to 110'),
        weight: yup.number().min(1, 'Value must be greater than 0')
            .max(500, 'Value must be less than or equal to 500'),
        height: yup.number().min(1, 'Value must be greater than 0')
            .max(8, 'Value must be less than or equal to 8'),
        oxygen_saturation: yup.number().min(0, 'Value must be 0 or greater')
            .max(100, 'Value must be less than or equal to 100'),
        consciousness_level: yup.number().min(0, 'Value must be 0 or greater')
            .max(10, 'Value must be less than or equal to 10'),
        pain_level: yup.number().min(0, 'Value must be 0 or greater')
            .max(10, 'Value must be less than or equal to 10'),
    })
}

const vitalSignValidation = {
    initialValues: {
        vitalsign: vitalSign.initialValues
    },
    schema: yup.object().shape({
        vitalsign: vitalSign.schema
    })
}

const clinicalNotesValidation = {
    initialValues: {
        notes: ''
    },
    schema: yup.object().shape({
        notes: yup.string().max(255, "maximum 255 characters allow"),
    })
}

const medication = {
    initialValues: {
        name: "",
        description: ""
    },
    schema: yup.object().shape({
        name: yup.string().max(40, "max 40 character allowed").required("name required"),
        description: ''
    })
}

const addMedicationValidation = {
    initialValues: {
        genericmedicine: medication.initialValues
    },
    schema: yup.object().shape({
        genericmedicine: medication.schema
    })
}

const service = {
    initialValues: {
        name: "",
        description: "",
        fee: '',
        expected_completion_time: '',
        type: ''
    },
    schema: yup.object().shape({
        name: yup.string().max(40, "max 40 character allowed").required("name required"),
        fee: yup.string().required("fee required"),
        expected_completion_time: yup.string().matches(/^\d+[mhdw]|^\d+mo$/, 'format is invalid')
    })
}

const serviceValidation = {
    initialValues: {
        service: service.initialValues
    },
    schema: yup.object().shape({
        service: service.schema
    })
}

const prescription = {
    initialValues: {
        generic_medicine_id: "",
        dosage: "",
        instruction: "",
        frequency: "",
        time_of_day: ""
    },
    schema: yup.object().shape({
        generic_medicine_id: yup.string().required("select medicine name"),
        dosage: yup.number().min(1, "minimum 1 dosage required").max(100, "maximum value can be 100"),
        instruction: yup.string(),
        frequency: yup.number().min(1, "minimum 1 dosage required").max(30, "maximum value can be 30"),
        time_of_day: yup.string().matches(/^(?:morning|afternoon|evening)(?:\/(?:morning|afternoon|evening))*$/, 'Invalid time of day').nullable(true)
    })
}

const addPrescriptionValidation = {
    initialValues: {
        medication: prescription.initialValues
    },
    schema: yup.object().shape({
        medication: prescription.schema
    })
}

const appointmentDate = {
    initialValues: {
        first_date: "",
        last_date: ""
    },
    schema: yup.object().shape({
        first_date: yup.date().required("start date required"),
        last_date: yup.date().required("end date required")
    })
}


const appointmentList = {
    initialValues: {
        appointment: appointmentDate.initialValues
    },
    schema: yup.object().shape({
        appointment: appointmentDate.schema
    })
}

const organisationSetting = {
    initialValues: {
        name: "",
        date: "",
        recurrence_end_date: "",
        start_time: "",
        end_time: ""
    },
    schema: yup.object().shape({
        name: yup.string().required("shift name date required"),
        date: yup.string().required("shift date required"),
        recurrence_end_date: yup.string().required("shift end-date required"),
        start_time: yup.string().required("shift start-time required"),
        end_time: yup.string().required("shift end-time required")
    })
}

const organisationSettingValidation = {
    initialValues: {
        shift: organisationSetting.initialValues
    },
    schema: yup.object().shape({
        shift: organisationSetting.schema
    })
}

const individualTimeZone = {
    initialValues: {
        timezone: '',
        currency: '',
        support_email: '',
        contact_number: ''
    },
    schema: yup.object().shape({
        timezone: yup.string().required('Timezone is required'),
        currency: yup.string().required('Currency is required'),
        support_email: yup.string().required('Email is required').email('Please enter a valid email address'),
        contact_number: yup.string().matches(/^[+]?[0-9]{7,15}$/, 'phone number must start with + and between 7 to 15 numeric values').trim().required('Phone number is required')
    })
}

const timeZones = {
    initialValues: {
        organizationconfig: individualTimeZone.initialValues
    },
    schema: yup.object().shape({
        organizationconfig: individualTimeZone.schema
    })
}

const paymentValidation = {
    initialValues: {
        gateway_credentials: {
            secret_key: '',
            signing_secret: ''
        },
        gateway_type: 'stripe',
        enabled: ''
    },
    schema: yup.object().shape({
        enabled: yup.string().required('select enable/disable'),
        gateway_credentials: yup.object().shape({
            secret_key: yup.string().required('secret key is required'),
            signing_secret: yup.string().required('signing key required')
        })
    })
};

const payment = {
    initialValues: {
        gateway_config: paymentValidation.initialValues
    },
    schema: yup.object().shape({
        gateway_config: paymentValidation.schema
    })
}

const cashPaymentValidation = {
    initialValues: {
        gateway_type: 'cash',
        enabled: ''
    },
    schema: yup.object().shape({
        enabled: yup.string().required('select enable/disable')
    })
};

const cash = {
    initialValues: {
        gateway_config: cashPaymentValidation.initialValues
    },
    schema: yup.object().shape({
        gateway_config: cashPaymentValidation.schema
    })
}

const checkValidation = (value) => {
    let regexPattern = /^(\d{3}-\d{2}-\d{4}|\d{9}|\d{5}-\d{7}-\d|\d{13})$/;
    const isValidInput = regexPattern.test(value);
    return isValidInput
}

const general = {
    initialValues: {
        admin_managed_schedule: '',
    }
};

const generalValidation = {
    initialValues: {
        profile: general.initialValues
    },
    schema: yup.object().shape({
        profile: general.schema
    })
}

const reschedule = {
    initialValues: {
        date: '',
        start_time: ''
    },
    schema: yup.object().shape({
        date: yup.string().required('select date'),
        start_time: yup.string().required('select time slot')
    })
}

const rescheduleValidation = {
    initialValues: {
        appointment: reschedule.initialValues
    },
    schema: yup.object().shape({
        appointment: reschedule.schema
    })
}

export {
    userValidation, addressValidation, organizationValidation, signUpValidation, departmentAdditionValidation,
    signInValidation, designationValidation, userProfileValidation, patientProfileValidation, userProfileEmail, forgotInitialValues, updatePasswordInitialValues,
    timeZones, timingForm, dayOffValidation, appointmentValidation, vitalSignValidation, verifyEmailPassword,
    clinicalNotesValidation, addMedicationValidation, serviceValidation, addPrescriptionValidation, appointmentList, organisationSettingValidation, payment, cash, checkValidation,
    generalValidation, certificationValidation, degreeValidation, rescheduleValidation
};