import React from "react";
import "../client/scss/accessDenied.scss";
import image from "../client/images/403image.jpg";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AccessDenied() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <Box className="forbidden-page">
            <Box className="forbidden-content">
                <img height='200px' src={image} alt="403" className="error-image" />

                <Typography variant="h1" className="error-code">
                    403
                </Typography>
                <Typography variant="h4" className="error-message">
                    We are Sorry...
                </Typography>
                <Typography variant="body1" className="error-description">
                    The page you're trying to access has restricted access. Please refer to your system administrator.
                </Typography>
                <Button variant="contained" color="primary" onClick={goBack} className="go-back-button">
                    Go Back
                </Button>
            </Box>
        </Box>
    );
}

export default AccessDenied;
