import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timer: '',
    response: {
        status: "",
        successMsg: "",
        errorMsg: "",
        msgState: false
    },
    urlWithParams: "",
    updatedPassResponse: "",
    updateTimer: ''
}

const updatePasswordSlice = createSlice({
    name: 'updatepassword',
    initialState,
    reducers: {
        setTimer: (state, action) => {
            state.timer = action.payload;
        },
        setResponse: (state, action) => {
            state.response = action.payload;
        },
        resetResponse: (state) => {
            state.response = {
                status: "",
                successMsg: "",
                errorMsg: "",
                msgState: false
            };
        },
        urlWithParams: (state, action) => {
            state.urlWithParams = action.payload;
        },
        setUpdatedResponse: (state, action) => {
            state.updatedPassResponse = action.payload;
        },
        setUpdateTimer: (state, action) => {
            state.updateTimer = action.payload;
        }
    },
});

export const { setTimer, setResponse, resetResponse, urlWithParams, setUpdatedResponse, setUpdateTimer } = updatePasswordSlice.actions;

export default updatePasswordSlice.reducer;