import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    openModal: false,
    isChecked: false,
    mapData: [],
    mapDataRecep: [],
    doctorPopup: "",
    shiftModal: '',
    doctorId: '',
    doctorTimingData: [],
    mapDepartmentsList: [],
    leaveModal: false,
    offDays: '',
    searchQuery: '',
    order: ''
}

const doctorSlice = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
        setDoctor: (state, action) => {
            state.data = action.payload;
        },
        setMapData: (state, action) => {
            state.mapData = action.payload;
        },
        setMapReceptionist: (state, action) => {
            state.mapDataRecep = action.payload;
        },
        addDoctor: (state, action) => {
            state.data.push(action.payload)
        },
        setDocModal: (state, action) => {
            state.openModal = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setDoctorPopup: (state, action) => {
            state.doctorPopup = action.payload;
        },
        setShiftPopup: (state, action) => {
            state.shiftModal = action.payload;
        },
        dispatchDoctorId: (state, action) => {
            state.doctorId = action.payload;
        },
        setAsynDepartments: (state, action) => {
            state.mapDepartmentsList = action.payload;
        },
        fetchDoctorTimings: (state, action) => {
            state.doctorTimingData = action.payload;
        },
        updateTiming: (state, action) => {
            state.doctorTimingData = action.payload;
        },
        addDoctorTimings: (state, action) => {
            state.doctorTimingData.push(action.payload);
        },
        deleteDoctorTiming: (state, action) => {
            state.doctorTimingData = action.payload;
        },
        setLeaveModal: (state, action) => {
            state.leaveModal = action.payload;
        },
        addDayOff: (state, action) => {
            state.offDays = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    },
});

export const { setDoctor, setMapData, setMapReceptionist, addDoctor, setDocModal, setIsChecked,
    setDoctorPopup, setShiftPopup, dispatchDoctorId, fetchDoctorTimings, updateTiming, deleteDoctorTiming, addDoctorTimings,
    setAsynDepartments, setLeaveModal, addDayOff, setSearchQuery, setOrder } = doctorSlice.actions;

export default doctorSlice.reducer;