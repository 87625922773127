import React from "react";
import { useSelector } from "react-redux";
import AccessDenied from "../AccessDenied";

// Helper function to check conditions
function checkConditions(abilityConditions, requiredUserData) {
  // If no conditions are defined in ability or requiredAbility, return true
  if (
    (!abilityConditions || Object.keys(abilityConditions).length === 0) &&
    (!requiredUserData || Object.keys(requiredUserData).length === 0)
  ) {
    return true;
  }

  // If requiredAbility has user conditions, compare them with the ability's conditions
  if (requiredUserData && Object.keys(requiredUserData).length > 0) {
    return Object.keys(requiredUserData).every((key) => {
      if (typeof requiredUserData[key] === "object") {
        // Handle nested conditions like user: { admin_managed_schedule: true }
        return checkNestedConditions(
          requiredUserData[key],
          abilityConditions[key]
        );
      }
      return requiredUserData[key] === abilityConditions[key];
    });
  }

  return true; // If no specific conditions to check, return true
}

// Helper function to check nested conditions
function checkNestedConditions(requiredNested, abilityNested) {
  if (!abilityNested || typeof abilityNested !== "object") {
    return false;
  }
  return Object.keys(requiredNested).every((key) => {
    return requiredNested[key] === abilityNested[key];
  });
}

const AccessControlComponent = (props) => {
  const auth = useSelector((state) => state.auth.data);
  const userAbilities = auth?.abilities;
  const requiredAbility = props.requiredAbility;
  var isAuthorized = false;
  let pgCntrl = false;

  if (userAbilities) {
    var requiredAbilities = requiredAbility;

    isAuthorized = userAbilities.some(function (ability) {
      return requiredAbilities.some(function (requiredAbility) {
        const actionMatches =
          ability.actions.indexOf(requiredAbility && requiredAbility.action) !==
          -1;
        const subjectMatches =
          ability.subjects.indexOf(
            requiredAbility && requiredAbility.subject
          ) !== -1;

        // Extract user data from requiredAbility.conditions and check conditions
        const requiredUserData =
          requiredAbility.conditions && requiredAbility.conditions.user;
        const conditionsMatch = checkConditions(
          ability.conditions,
          requiredUserData
        );

        return actionMatches && subjectMatches && conditionsMatch;
      });
    });

    pgCntrl = props?.pageControl;
  }

  if (isAuthorized) {
    return <props.component />;
  } else if (pgCntrl == true) {
    return <AccessDenied />;
  }
};

const AccessControlHOC = (props) => {
  return (
    <AccessControlComponent
      component={props.component}
      requiredAbility={props.ability}
      pageControl={props.pageControl}
    />
  );
};

export default AccessControlHOC;
