import React from "react";
import "../../../src/client/scss/forgotpassword.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { forgotInitialValues } from "../../scripts/helpers/validationScripts";
import logo from "../../client/images/itnoa-dark.png"
import forgotImg from "../../client/images/resetPassword.jpg"
import api from "../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setTimer, setResponse, resetResponse } from "../../reducers/forgotPasswordSlice";
import CustomAlert from "../../components/alert/CustomAlert";
import { userSetLoading } from "../../reducers/loaderSlice";

function ForgotPassword() {

    const dispatch = useDispatch();

    const response = useSelector((state) => state.forgotpassword.response);
    const timer = useSelector((state) => state.forgotpassword.timer);
    const loading = useSelector((state) => state.forgotpassword.loading);

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {

        dispatch(resetResponse());
        dispatch(userSetLoading(true));
        dispatch(setTimer(true));

        try {

            const response = await api.post("api/v1/auth/password", values);
            dispatch(setResponse({
                status: "success",
                successMsg: response.data.message,
                errorMsg: "",
                msgState: true
            }))

        } catch (error) {
            dispatch(setResponse({
                status: "error",
                successMsg: "",
                errorMsg: error.response.data.errors,
                msgState: true,
            }));
        }
        finally {
            setSubmitting(false);
            dispatch(userSetLoading(false));
        }
    };

    return (
        <div className="page-main">
            <div className="container forgot-page">
                <div className="header-hms"><img src={logo} alt="logo" /></div>

                {response.status !== "" &&
                    response.status !== "undefined" &&
                    (
                        <CustomAlert
                            type={response.status}
                            visible={response.msgState}
                            title={response.status === "success" ? "Success" : "Error"}
                            desc={
                                response.status === "success"
                                    ? response.successMsg
                                    : response.errorMsg
                            }
                            isFixed={timer}
                        />
                    )}
                <div className="forgot-panel">
                    <div className="forget-form">
                        <p className="page-title">Forgot Password</p>
                        <Formik
                            initialValues={forgotInitialValues.initialValues}
                            validationSchema={forgotInitialValues.schema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors }) => (
                                <Form>
                                    <div>
                                        <div className="form-input">
                                            <label htmlFor="email">Enter Your Email:</label>
                                            <Field
                                                type="email"
                                                id="email"
                                                name="email"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                className="invalid-feedback d-block"
                                                component="span"
                                            />
                                        </div>
                                        <button className="btn submitBtn" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                {loading && <div className="form-loader"></div>}
            </div>
        </div >
    );
}

export default ForgotPassword;
