import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    openModal: false,
    isChecked: false,
    searchQuery: '',
    order: ''
}

const patientSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        setPatient: (state, action) => {
            state.data = action.payload;
        },
        addPatient: (state, action) => {
            state.data.push(action.payload)
        },
        setModal: (state, action) => {
            state.openModal = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    },
});

export const { setPatient, addPatient, setModal, setIsChecked, setSearchQuery, setOrder } = patientSlice.actions;

export default patientSlice.reducer;