import React from 'react';

const FallBack = () => {
    return (
        <>
            <p>Something went wrong</p>
        </>
    )
}

export default FallBack;