import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    department: [],
    doctors: [],
    filterValues: [],
    modalOpen: false
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setDepartments: (state, action) => {
            state.department = action.payload;
        },
        setDoctors: (state, action) => {
            state.doctors = action.payload;
        },
        setFilter: (state, action) => {
            state.filterValues = action.payload;
        },
        setModal: (state, action) => {
            state.modalOpen = action.payload;
        }
    }
});

export const { setDepartments, setDoctors, setFilter, setModal } = filterSlice.actions;

export default filterSlice.reducer;