import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timer: '',
    response: {
        status: "",
        successMsg: "",
        errorMsg: "",
        msgState: false
    }
}

const forgotPasswordSlice = createSlice({
    name: 'forgotpassword',
    initialState,
    reducers: {
        setTimer: (state, action) => {
            state.timer = action.payload;
        },
        setResponse: (state, action) => {
            state.response = action.payload;
        },
        resetResponse: (state) => {
            state.response = {
                status: "",
                successMsg: "",
                errorMsg: "",
                msgState: false
            };
        }
    },
});

export const { setTimer, setResponse, resetResponse } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;