import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    checkBox: false,
    modal: '',
    updateBtn: false,
    updateMedicine: {
        medicineName: '',
        medicineDesc: '',
    },
    medicineListId: '',
    searchQuery: '',
    order: ''
}

const medicineSlice = createSlice({
    name: 'medicine',
    initialState,
    reducers: {
        setMedicine: (state, action) => {
            state.data = action.payload;
        },
        addMedicine: (state, action) => {
            state.data.push(action.payload)
        },
        setUpdateMedicines: (state, action) => {
            state.data = action.payload;
        },
        setCheckBox: (state, action) => {
            state.checkBox = action.payload;
        },
        setModal: (state, action) => {
            state.modal = action.payload;
        },
        setUpdateBtn: (state, action) => {
            state.updateBtn = action.payload;
        },
        setMedicineListId: (state, action) => {
            state.medicineListId = action.payload;
        },
        setupdateMedicine: (state, action) => {
            state.updateMedicine = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    },
});

export const { setMedicine, addMedicine, setUpdateMedicines, setCheckBox, setModal, setUpdateBtn, setMedicineListId, setupdateMedicine, setSearchQuery, setOrder } = medicineSlice.actions;

export default medicineSlice.reducer;