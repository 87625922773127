import React, { useEffect } from "react";
import "../../../src/client/scss/forgotpassword.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { updatePasswordInitialValues } from "../../scripts/helpers/validationScripts";
import logo from "../../client/images/itnoa-dark.png"
import forgotImg from "../../client/images/resetPassword.jpg"
import api from "../../config/axios";
import CustomAlert from "../../components/alert/CustomAlert";
import { GetQueryParam } from "../../scripts/helpers/urlHelpers";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setTimer, setResponse, urlWithParams, setUpdatedResponse, setUpdateTimer } from "../../reducers/updatePasswordSlice";
import { userSetLoading } from "../../reducers/loaderSlice";
import ShowToast from "./Toast";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';

function UpdatePassword() {
    const dispatch = useDispatch();

    const response = useSelector((state) => state.updatepassword.response);
    const timer = useSelector((state) => state.updatepassword.timer);
    const urlParams = useSelector((state) => state.updatepassword.urlWithParams);
    const updatedPassResponse = useSelector((state) => state.updatepassword.updatedPassResponse);
    const updateTimer = useSelector((state) => state.updatepassword.updateTimer);
    const location = useLocation();
    const query = location.search.substring(1); // Remove the leading '?' character
    const navigate = useNavigate();

    const verifyToken = async (reset_password_token) => {
        try {

            dispatch(setTimer(true));
            dispatch(userSetLoading(true));

            const queryParams = {
                reset_password_token: reset_password_token,
            };

            const response = await api.get("/api/v1/auth/password/edit", { params: queryParams });

            dispatch(urlWithParams(response.data.redirect_url));
            dispatch(setResponse({
                status: "success",
                successMsg: "Please update your password",
                errorMsg: "",
                msgState: true
            }));

        } catch (error) {
            dispatch(setResponse({
                status: "error",
                successMsg: "",
                errorMsg: error.response.data.error,
                msgState: true
            }));
        }
        dispatch(userSetLoading(false));
    }

    useEffect(() => {

        var reset_password_token = GetQueryParam('reset_password_token', query);
        if (reset_password_token) {
            verifyToken(reset_password_token)
        };
    }, []);

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {

        dispatch(setUpdateTimer(true));
        dispatch(userSetLoading(true));

        const url = new URL(urlParams);
        const params = Object.fromEntries(url.searchParams.entries());
        const headers = {
            'access-token': params['access-token'],
            'client': params['client'],
            'config': params['config'],
            'expiry': params['expiry'],
            'token': params['token'],
            'uid': params['uid'],
            'token-type': 'Bearer'
        };
        try {

            const response = await api.put("api/v1/auth/password", values, { headers: headers })

            ShowToast(response.data.message, "success");
            dispatch(userSetLoading(false));
            setSubmitting(false);

        } catch (error) {
            if (error.response.status == "422") {
                setErrors(error.response.data.error);
            }
            else {
                dispatch(setUpdatedResponse({
                    status: "error",
                    successMsg: "",
                    errorMsg: error.response.data.errors[0],
                    msgState: true
                }));
            }
            dispatch(userSetLoading(false));
        }
        finally {
        }
    };

    return (
        <>
            <div className="page-main">
                <div className="container password-update">
                    <div className="header-hms"><img src={logo} alt="logo" /></div>
                    <ToastContainer />

                    {response.status !== "" &&
                        response.status !== "undefined" &&
                        (
                            <CustomAlert
                                type={response.status}
                                visible={true}
                                title={response.status === "success" ? "Email link verified" : "Error"}
                                desc={
                                    response.status === "success"
                                        ? response.successMsg
                                        : response.errorMsg
                                }
                                isFixed={timer}
                            />
                        )
                    }

                    {updatedPassResponse.status == "success" &&
                        (
                            <CustomAlert
                                type={updatedPassResponse.status}
                                visible={true}
                                title={updatedPassResponse.status === "success" ? updatedPassResponse.status : ""}
                                desc={
                                    updatedPassResponse.status === "success"
                                        ? updatedPassResponse.successMsg
                                        : updatedPassResponse.errorMsg
                                }
                                isFixed={updateTimer}
                            />
                        )
                    }
                    {updatedPassResponse.status == "error" &&
                        (
                            <CustomAlert
                                type={updatedPassResponse.status}
                                visible={true}
                                title={updatedPassResponse.status === "success" ? updatedPassResponse.status : ""}
                                desc={
                                    updatedPassResponse.status === "success"
                                        ? updatedPassResponse.successMsg
                                        : updatedPassResponse.errorMsg
                                }
                                isFixed={updateTimer}
                            />
                        )
                    }
                    <div className="forgot-panel">

                        <div className="forget-form">
                            <p className="page-title">Reset Password</p>

                            <Formik
                                initialValues={updatePasswordInitialValues.initialValues}
                                validationSchema={updatePasswordInitialValues.schema}
                                onSubmit={handleSubmit}
                            >
                                {({ errors }) => (
                                    <Form>
                                        <div>
                                            <div className="form-input">
                                                <label htmlFor="password">Password</label>
                                                <Field
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                />
                                                <ErrorMessage
                                                    name="password"
                                                    className="invalid-feedback d-block"
                                                    component="span"
                                                />
                                            </div>
                                            <div className="form-input">
                                                <label htmlFor="password_confirmation">Confirm password</label>
                                                <Field
                                                    type="password"
                                                    id="password_confirmation"
                                                    name="password_confirmation"
                                                />
                                                <ErrorMessage
                                                    name="password_confirmation"
                                                    className="invalid-feedback d-block"
                                                    component="span"
                                                />
                                            </div>
                                            <button className="mb-2 btn submitBtn" type="submit">
                                                Update Password
                                            </button>
                                            <Button type="button" component={Link} href="/login" className="btn-back">
                                                Login
                                            </Button>
                                        </div>
                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdatePassword;
