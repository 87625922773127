
import React, { useState } from 'react';

const PasswordProtectionMiddleware = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogin = () => {
        // In a real app, you should validate the password with a secure backend
        // For this example, we'll use a hardcoded password.
        const validPassword = 'itnoa786';
        const enteredPassword = prompt('Enter Password:');

        if (enteredPassword === validPassword) {
            setIsLoggedIn(true);
            localStorage.setItem('isLoggedIn', 'true');
        } else {
            setIsLoggedIn(false);
        }
    };

    // For staging environment, prompt for password on all pages
    if (!isLoggedIn) {
        // Check if the user is already authenticated (has entered the password before)
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        if (storedIsLoggedIn === 'true') {
            setIsLoggedIn(true);
        } else {
            // If not authenticated, prompt for password
            handleLogin();
        }
    }

    // Return the children components when authenticated or if not in staging
    return isLoggedIn ? (
        <>{children}</>
    ) : null;
};

export default PasswordProtectionMiddleware;
