import React, { useContext } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ErrorBoundary } from 'react-error-boundary';
import FallBack from '../../components/admin/FallBack';
import "../../client/scss/dashboard.scss";
import "../../client/scss/lists.scss";
import Sidebar from '../../components/admin/Sidebar';
import Topbar from '../../components/admin/Topbar';
import { SidebarMenuContext } from '../../scripts/utils/SidebarMenuContext';
import DashboardContent from '../../components/admin/DashboardContent';

const Dashboard = () => {
  const sidebarContext = useContext(SidebarMenuContext);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <div className='main-sidebar'>
            <ErrorBoundary fallback={<FallBack />}>

              <Sidebar />
            </ErrorBoundary>
          </div>
          <div
            className={`main-content ${sidebarContext.collapsed ? "" : "main-content-expanded"
              }`}
          >            <ErrorBoundary fallback={<FallBack />}>

              <Topbar />
            </ErrorBoundary>
            <ErrorBoundary fallback={<FallBack />}>

              <DashboardContent />
            </ErrorBoundary>
          </div>
        </Grid>
      </Box>
    </div>
  )
}

export default Dashboard