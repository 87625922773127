import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL // Replace with your server URL
});

export const headersToSave = ['x-jwt-token', 'access-token', 'uid', 'expiry', 'token-type', 'client']; // List of headers to retrieve

// Save headers from response to cookies
export const saveHeadersFromResponse = (response) => {
    headersToSave.forEach((header) => {
        if (response.headers[header]) {
            // save header to local storage
            localStorage.setItem(header, response.headers[header]);
        }
    });
};

export const removeSessionFromStorage = () => {
    headersToSave.forEach((header) => {
        // save header to local storage
        localStorage.removeItem(header);

    });
}

// Retrieve header from lolcal storage
export const getSavedHeader = (headerName) => {
    // Retrieve header value from local storage
    const header = localStorage.getItem(headerName);
    if (header) {
        return header;
    }
    return null;
};

// Add headers from local storage to request
api.interceptors.request.use((config) => {
    headersToSave.forEach((header) => {
        const value = getSavedHeader(header);
        if (value) {
            config.headers[header] = value;
        }
    });
    // Add Content-Type header if not provided
    if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
});

// Save headers from response
api.interceptors.response.use(
    (response) => {
        saveHeadersFromResponse(response);
        return response;
    },
    (error) => {
        saveHeadersFromResponse(error.response);
        return Promise.reject(error);
    }
);


export default api;


