import React from "react";

function Modal(props) {
  return (
    <div className="popup-modal">
      <div className={`modal-content ${props.title}`}>{props.children}</div>
    </div>
  );
}

export default Modal;
