import React, { useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { Formik, Field, ErrorMessage } from "formik"
import api from "../../config/axios";
import siteLogo from "../../client/images/site-logo.png"
import "../../client/scss/auth.scss";
import FeatureColumn from '../../components/auth/FeatureColumn';
import JWTService from '../../scripts/services/JWTService';
import { loginSuccess } from '../../reducers/authSlice';
import { userSetLoading } from '../../reducers/loaderSlice';
import { signInValidation } from "../../scripts/helpers/validationScripts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowToast from "./Toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Tooltip, IconButton } from "@mui/material";

function SignIn() {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [showPassword, setShowPassword] = useState(false);

	const [formHeight, setFormHeight] = useState(0);
	const loginFormRef = useRef(null);

	const updateFormHeight = () => {
		if (loginFormRef.current) {
			let height = loginFormRef.current.clientHeight;

			let viewportHeight = window.innerHeight;
			let totalScrollableHeight = document.body.scrollHeight;
			let max = Math.max(viewportHeight, totalScrollableHeight);
			let maximum = Math.max(max, height);
			if (max > height) {
				setFormHeight(maximum);
			}
			else {
				setFormHeight(height);

			}
		}
	};

	useEffect(() => {

		updateFormHeight();

		window.addEventListener('resize', updateFormHeight);

	}, [location]);


	const submitLogin = (values, { setSubmitting, setErrors }) => {
		dispatch(userSetLoading(true));
		setSubmitting(true);


		api.post("api/v1/auth/sign_in", values).then(res => {
			if (res.status === 200) {
				const { data } = res;
				dispatch(loginSuccess(data.data));
				dispatch(userSetLoading(false));

				navigate("/dashboard");
			}
		})
			.catch(error => {
				if (error.response.status === 422) {
					ShowToast(error.response.data.error, "error");
				}
				else {
					ShowToast(error.response.data.error[0], "error");
				}
				dispatch(userSetLoading(false));
			});

	}

	if (window.innerWidth > 767) {
		var containerStyle = {
			height: formHeight,
		};
	}
	// Redirect to the previous route if a token is already set


	const token = JWTService.getToken();

	if (token) {

		return <Navigate to={"/dashboard"} replace />;
	}

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<div className="login-wrapper">
				<Container fluid>
					<Row>
						<Col xs={12} md={7} lg={7} className="login-fill" style={containerStyle}>
							<FeatureColumn />
						</Col>
						<Col xs={12} md={5} lg={5}>

							<Formik initialValues={signInValidation.initialValues}
								validationSchema={signInValidation.schema}
								validateOnChange={true}
								validateOnBlur={true}
								onSubmit={submitLogin}
							>
								{(formik) => {
									const {
										values,
										handleSubmit,
										errors,
										touched,
									} = formik;
									return (

										<div className="login-form" ref={loginFormRef}>
											<ToastContainer />

											<div className='form-top'>
												<Row className='align-items-center'>
													<Col xs={6}>
														<Image width={200} src={siteLogo} alt="hms-logo" />
													</Col>
													<Col xs={6}>
														<Button className='btn-register' component={Link} href="/register">
															Register
														</Button>
													</Col>
												</Row>
											</div>

											<div className='form-content'>
												<h3 className="form-title">Login</h3>
												<form onSubmit={handleSubmit}>
													<div className="form-group">
														<label htmlFor="email">Email</label>
														<Field type="text" placeholder='Please enter your email' name="email"
															className={`form-control ${errors?.email && touched.email ? "is-invalid" : null}`} value={values.email}
														/>
														<ErrorMessage name="email" component="span" className="invalid-feedback d-block" />
													</div>
													<div className="form-group">
														<label htmlFor="password">Password</label>
														<div className="password-field">

															<Field type={showPassword ? "text" : "password"} placeholder='Enter your password' name="password"
																className={`form-control ${errors?.password && touched.password ? "is-invalid" : null}`} value={values.password}
															/>
															<Tooltip
																title={
																	showPassword
																		? "Hide Password"
																		: "Show Password"
																}
															>
																<IconButton
																	onClick={togglePasswordVisibility}
																	size="large"
																>
																	{showPassword ? (
																		<VisibilityOff />
																	) : (
																		<Visibility />
																	)}
																</IconButton>
															</Tooltip>
														</div>
														<ErrorMessage name="password" component="span" className="invalid-feedback d-block" />
													</div>

													<div className="form-group">
														<label htmlFor="organization.name">Organization Name</label>
														<Field type="text" placeholder='Enter name of organization' name="organization.name"
															className={`form-control ${errors?.organization?.name && touched.organization?.name ? "is-invalid" : null}`} value={values?.organization?.name}
														/>
														<ErrorMessage name="organization.name" component="span" className="invalid-feedback d-block" />
													</div>

													<p className='text-end'>
														<a className='forgot-password' href="/forgot_password">Forgot Password ?</a>
													</p>

													<div className="button-group">
														<button type="submit" className='btn btn-block btn-auth'>Login</button>
													</div>
												</form>
											</div>
										</div>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default SignIn