import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    updateBtn: false,
    openModal: false,
    isChecked: false,
    searchQuery: '',
    order: '',
    updateDepartmentValues: {
        departmentName: '',
        departmentDesc: '',
    },
    departmentListId: ''
}

const departmentSlice = createSlice({
    name: 'departments',
    initialState,
    reducers: {
        setDepartments: (state, action) => {
            state.data = action.payload;
        },
        addDepartment: (state, action) => {
            state.data.push(action.payload)
        },
        updateDepartment: (state, action) => {
            state.data = action.payload;
        },
        setUpdateBtn: (state, action) => {
            state.updateBtn = action.payload;
        },
        setModal: (state, action) => {
            state.openModal = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setUpdateDepartmentValues: (state, action) => {
            state.updateDepartmentValues = action.payload;
        },
        setDepartmentListId: (state, action) => {
            state.departmentListId = action.payload;
        }
    },
});

export const { setDepartments, addDepartment,
    updateDepartment, setUpdateBtn, setModal, setIsChecked, setSearchQuery, setOrder, setUpdateDepartmentValues, setDepartmentListId } = departmentSlice.actions;

export default departmentSlice.reducer;