
import { removeSessionFromStorage } from "../../config/axios";

const JWTService = {
    storeToken: (token) => {
        localStorage.setItem('access-token', token);
    },
    getToken: () => {
        return localStorage.getItem('access-token');
    },
    removeToken: () => {
        localStorage.removeItem("access-token");
        removeSessionFromStorage();
    }
}

export default JWTService;

