import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false
}

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        userSetLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const { userSetLoading } = loaderSlice.actions;

export default loaderSlice.reducer;