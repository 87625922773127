import React, { useEffect, useState, useContext } from 'react';
import Link from '@mui/material/Link'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom"
import { SidebarMenuContext } from "../../scripts/utils/SidebarMenuContext";

const theme = createTheme({
  palette: {
    background: {
      transparent: 'transparent',
    },
    text: {
      primary: '#03A9F4',
      secondary: '#fff',
    },
    active: {
      background: "#03A9F4",
      '&:hover': {
        background: "#03A9F4",
      }
    },
    action: {
      active: '#03A9F4',
    }
  },
});

export default function MenuItemList(props) {
  const sidebarContext = useContext(SidebarMenuContext);
  const sidebar = props.pro;
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleNavigation = (endpoint) => {
    // Use the navigate function to navigate to the selected endpoint
    if (endpoint.endPoint) {
      navigate(endpoint.endPoint);
      sidebarContext.setCollapsedMob(false)

    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="sidebar-menu">
      <ThemeProvider theme={theme}>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.transparent' }}
          component="nav">

          {
            sidebar?.map((menuItem, index) => {
              return (
                <div className={menuItem.hasSubMenu ? "submenu-item" : ""} key={index}>
                  <Link onClick={(e) => { e.preventDefault(); handleNavigation(menuItem) }}>
                    <ListItemButton className='menu-button' onClick={() => handleListItemClick(index)}
                      selected={selectedIndex === index}>
                      <ListItemIcon sx={{ color: 'text.secondary' }} className="menu-icon">
                        <Icon sx={{ fontSize: 24 }}>{menuItem.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText sx={{ color: 'text.secondary' }} className="menu-text">{menuItem.displayName}</ListItemText>
                      {sidebarContext.collapsed && menuItem.hasSubMenu && (
                        selectedIndex === index ? <ExpandLess /> : <ExpandMore />)
                      }
                    </ListItemButton>
                  </Link>
                  {sidebarContext.collapsed &&
                    menuItem.hasSubMenu &&
                    <Collapse in={selectedIndex == index} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {menuItem.nestedEndpoints.map((nestedItem, index) => {
                          return (
                            <Link key={index} onClick={(e) => { e.preventDefault(); handleNavigation(nestedItem) }}>
                              <ListItemButton className='menu-button' sx={{ pl: 4, color: 'text.secondary' }}>

                                <ListItemIcon sx={{ color: 'text.secondary' }} className="menu-icon">
                                  <Icon sx={{ fontSize: 24 }}>{nestedItem.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText className="menu-text" sx={{ color: 'text.secondary' }}> {nestedItem.displayName}</ListItemText>
                              </ListItemButton>
                            </Link>
                          )
                        })}
                      </List>
                    </Collapse>
                  }
                  {!sidebarContext.collapsed &&
                    menuItem.hasSubMenu &&
                    <div className="submenu">
                      <ul>
                        {menuItem.nestedEndpoints.map((nestedItem, index) => {
                          return (
                            <li key={index} onClick={(e) => { e.preventDefault(); handleNavigation(nestedItem) }}>
                              <span className="menu-icon">
                                <Icon sx={{ fontSize: 15 }}>{nestedItem.icon}</Icon>
                              </span>
                              <span className="menu-text"> {nestedItem.displayName}</span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  }
                </div>
              );
            })
          }
        </List>
      </ThemeProvider>
    </div >
  );
}