import { Icon } from '@mui/material';

const FeatureColumn = () => {
    return (
        <div>
            <div className="login-text">
                <h3 className="text-title">Hospital Management System</h3>
                <ul className="nav features-nav">
                    <li>
                        <span className="feature-icon">
                            <Icon>bar_chart_4_bars</Icon>
                        </span>
                        <p>Data Analytics</p>
                    </li>
                    <li>
                        <span className="feature-icon">
                            <Icon>calendar_today</Icon>
                        </span>
                        <p>Online Rescheduling</p>
                    </li>
                    <li>
                        <span className="feature-icon">
                            <Icon>assured_workload</Icon>
                        </span>
                        <p>OSHA Surveillance</p>
                    </li>
                    <li>
                        <span className="feature-icon">
                            <Icon>assignment</Icon>
                        </span>
                        <p>Performance Reporting</p>
                    </li>
                    <li>
                        <span className="feature-icon">
                            <Icon>receipt_long</Icon>
                        </span>
                        <p>Invoicing</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default FeatureColumn