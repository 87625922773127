import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    weekDays: [],
    modal: false,
    update: '',
    timeZone: [],
    time: ''
}

const userConfiguration = createSlice({
    name: 'userconfiguration',
    initialState,
    reducers: {
        setUserShift: (state, action) => {
            state.data = action.payload;
        },
        setShiftWeekDays: (state, action) => {
            state.weekDays = action.payload;
        },
        setModal: (state, action) => {
            state.modal = action.payload;
        },
        setUpdate: (state, action) => {
            state.update = action.payload;
        },
        setTimeZoneList: (state, action) => {
            state.timeZone = action.payload;
        },
        getTimeZone: (state, action) => {
            state.time = action.payload;
        },
        addShiftWeekDays: (state, action) => {
            state.weekDays.push(action.payload);
        }
    },
});

export const { setUserShift, setShiftWeekDays, setModal, setUpdate, setTimeZoneList, getTimeZone, addShiftWeekDays } = userConfiguration.actions;

export default userConfiguration.reducer;