import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    metrixData: [],
    doctorList: [],
    patientList: [],
    searchQuery: '',
    order: '',
    service: []
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setAppointment: (state, action) => {
            state.data = action.payload;
        },
        setMetrixStrength: (state, action) => {
            state.metrixData = action.payload;
        },
        setDoctor: (state, action) => {
            state.doctorList = action.payload;
        },
        setPatient: (state, action) => {
            state.patientList = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setService: (state, action) => {
            state.service = action.payload;
        }
    },
});

export const { setAppointment, setMetrixStrength, setDoctor, setPatient, setSearchQuery, setOrder, setService } = dashboardSlice.actions;

export default dashboardSlice.reducer;