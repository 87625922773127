import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    openModal: false,
    searchQuery: '',
    order: '',
    fileModal: ''
}

const allUsers = createSlice({
    name: 'allusers',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.data = action.payload;
        },
        addUsers: (state, action) => {
            state.data.push(action.payload);
        },
        setUserModal: (state, action) => {
            state.openModal = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setModalFile: (state, action) => {
            state.fileModal = action.payload;
        }
    },
});

export const { setUsers, addUsers, setUserModal, setSearchQuery, setOrder, setModalFile } = allUsers.actions;

export default allUsers.reducer;