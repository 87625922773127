import React, { useState, useEffect } from "react";
import ShowToast from "../../pages/admin/Toast";
import { useSelector, useDispatch } from "react-redux";
import api from "../../config/axios";
import { setMetrixStrength } from "../../reducers/dashboardSlice";
import { Skeleton } from "@mui/material";
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

const DashboardMetrics = () => {
  const dispatch = useDispatch();
  const metrixData = useSelector((state) => state.dashboard.metrixData);
  const [load, setLoad] = useState(true);
  let revenue = metrixData?.revenue?.stats.map((item) => ({
    name: item.month,
    value: item.revenue,
  }));
  let appointment = metrixData?.appointments?.monthly_details;
  let patient = metrixData.patients?.stats.map((item) => ({
    name: item.month,
    value: item.patients,
  }));
  let staff = metrixData?.staff?.stats;

  async function fetchMetrix() {
    try {
      const response = await api.get("/api/v1/dashboard/stats");

      if (response.data.status == "success") {
        const tempAppointments =
          response.data.data.appointments.monthly_details.flatMap((item) =>
            Object.entries(item).map(([key, value]) => ({ name: key, value }))
          );

        response.data.data.appointments.monthly_details = tempAppointments;
        if (response.data.data.staff) {
          const tempStaff = response.data.data.staff.stats.flatMap((item) =>
            Object.entries(item).map(([key, value]) => ({
              name: key,
              value,
            }))
          );
          response.data.data.staff.stats = tempStaff;
        }

        dispatch(setMetrixStrength(response.data.data));
      }
    } catch (error) {
      ShowToast(error.response.data.error, "error");
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fetchMetrix();
  }, []);

  const TooltipData = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "4px",
            border: "1px solid #ccc",
            fontSize: "10px",
          }}
        >
          <span>
            {name} : {value}
          </span>
        </div>
      );
    }
    return null;
  };

  const COLORS = ["#36A2EB", "#FF6384", "#FFCE56"];

  return (
    <div>
      <div className="row matrix-info">
        {!load && (
          <>
            {metrixData.revenue && (
              <>
                <div className="matrix-size col">
                  <div className="h-md-100 card">
                    <div className="card-header">
                      <h6 className="mb-0 mt-2">Monthly Revenue</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-start card-body">
                      <div className="text-start">
                        <h2 className="mb-1 text-700 fw-normal lh-1">
                          {metrixData.revenue.current_month}
                        </h2>
                        <div className="me-2 fs-11 badge bg-succes rounded-pill">{`${metrixData.revenue.percentage_change}%`}</div>
                      </div>
                      <div>
                        <BarChart
                          width={90}
                          height={70}
                          data={revenue}
                          margin={{
                            position: "relative",
                            top: 0,
                            right: 0,
                            bottom: 5,
                            left: 0,
                          }}
                        >
                          <Tooltip
                            contentStyle={{ padding: "0px", width: "10px" }}
                            content={<TooltipData />}
                          />
                          <Bar
                            type="monotone"
                            dataKey="value"
                            fill="#8884d8"
                            minPointSize={1}
                            barSize={5}
                          />
                        </BarChart>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {metrixData.appointments && (
              <>
                <div className="matrix-size col">
                  <div className="h-md-100 card">
                    <div className="card-header">
                      <h6 className="mb-0 mt-2">Appointments</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-start card-body">
                      <div className="text-start">
                        <h2 className="mb-1 text-700 fw-normal lh-1">
                          {metrixData.appointments.current_month}
                        </h2>
                        <div className="me-2 fs-11 badge bg-succes rounded-pill">{`${metrixData.appointments.percentage_change}%`}</div>
                      </div>
                      <div className="description">
                        <PieChart width={90} height={70}>
                          <Pie
                            data={appointment}
                            dataKey="value"
                            innerRadius={0}
                            outerRadius={30}
                            fill="#8884d8"
                            paddingAngle={0}
                          >
                            {appointment.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            wrapperStyle={{ zIndex: "1", fontSize: "10px" }}
                            contentStyle={{ padding: "4px" }}
                          />
                        </PieChart>
                        <div className="desc-name">
                          {appointment.map((entry, index) => (
                            <div key={`legend-${index}`}>
                              <div
                                style={{
                                  backgroundColor:
                                    COLORS[index % COLORS.length],
                                }}
                              ></div>
                              <span>{entry.name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {metrixData.patients && (
              <>
                <div className="matrix-size col">
                  <div className="h-md-100 card">
                    <div className="card-header">
                      <h6 className="mb-0 mt-2">Patients</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-start card-body">
                      <div className="text-start">
                        <h2 className="mb-1 text-700 fw-normal lh-1">
                          {metrixData.patients.current_month}
                        </h2>
                        <div className="me-2 fs-11 badge bg-succes rounded-pill">{`${metrixData.patients.percentage_change}%`}</div>
                      </div>
                      <div>
                        <LineChart
                          width={90}
                          height={70}
                          data={patient}
                          margin={{ top: 5, right: 10, bottom: 5, left: 5 }}
                        >
                          <Tooltip content={<TooltipData />} />
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {metrixData.staff && (
              <>
                <div className="matrix-size col">
                  <div className="h-md-100 card">
                    <div className="card-header">
                      <h6 className="mb-0 mt-2">Total Staff</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-start card-body">
                      <div className="text-start">
                        <h2 className="mb-1 text-700 fw-normal lh-1">
                          {metrixData.staff.total}
                        </h2>
                        <div className="me-2 fs-11 badge bg-succes rounded-pill">{`${metrixData.staff.percentage_change}%`}</div>
                      </div>
                      <div className="description">
                        <PieChart width={90} height={70}>
                          <Pie
                            data={staff}
                            dataKey="value"
                            innerRadius={15}
                            outerRadius={30}
                            fill="#8884d8"
                            paddingAngle={5}
                          >
                            {staff.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            wrapperStyle={{ fontSize: "10px" }}
                            contentStyle={{ padding: "4px" }}
                          />
                        </PieChart>
                        <div className="desc-name">
                          {staff.map((entry, index) => (
                            <div key={`legend-${index}`}>
                              <div
                                style={{
                                  backgroundColor:
                                    COLORS[index % COLORS.length],
                                }}
                              ></div>
                              <span>{entry.name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {load && (
          <>
            <div className="matrix-size col">
              <div className="h-md-100 card">
                <div className="card-header">
                  <h6 className="mb-0 mt-2">
                    <Skeleton animation="wave" />
                  </h6>
                </div>

                <div className="d-flex justify-content-between align-items-end card-body">
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <Skeleton
                        variant="rounded"
                        width={50}
                        height={50}
                        animation="wave"
                      />
                    </h2>
                    <div className="me-2 fs-11 badge bg-succes rounded-pill">
                      <Skeleton animation="wave" />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="matrix-size col">
              <div className="h-md-100 card">
                <div className="card-header">
                  <h6 className="mb-0 mt-2">
                    <Skeleton animation="wave" />
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-end card-body">
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <Skeleton
                        variant="rounded"
                        width={50}
                        height={50}
                        animation="wave"
                      />
                    </h2>
                    <div className="me-2 fs-11 badge bg-succes rounded-pill">
                      <Skeleton animation="wave" />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="matrix-size col">
              <div className="h-md-100 card">
                <div className="card-header">
                  <h6 className="mb-0 mt-2">
                    <Skeleton animation="wave" />
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-end card-body">
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <Skeleton
                        variant="rounded"
                        width={50}
                        height={50}
                        animation="wave"
                      />
                    </h2>
                    <div className="me-2 fs-11 badge bg-succes rounded-pill">
                      <Skeleton animation="wave" />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="matrix-size col">
              <div className="h-md-100 card">
                <div className="card-header">
                  <h6 className="mb-0 mt-2">
                    <Skeleton animation="wave" />
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-end card-body">
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <Skeleton
                        variant="rounded"
                        width={50}
                        height={50}
                        animation="wave"
                      />
                    </h2>
                    <div className="me-2 fs-11 badge bg-succes rounded-pill">
                      <Skeleton animation="wave" />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardMetrics;
