import { Icon } from "@mui/material";
import { useState, useEffect } from "react";
import "../../client/scss/_alert.scss";
const CustomAlert = (props) => {
    const { visible, type, title, desc, isFixed } = props;
    const [show, setShow] = useState(visible);

    // Hide the alert after 5 seconds
    if (isFixed) {
        setTimeout(() => {
            setShow(false);
        }, 4000);
    }

    if (show) {
        return (
            <div className={`cs-alert cs-alert-${type}`}>
                <Icon onClick={() => setShow(false)}>close</Icon>
                <h4 className="cs-alert-heading">{title}</h4>
                <p className="cs-alert-body">{desc}</p>
            </div>
        )
    }
}

export default CustomAlert;
