import React, { useState, useEffect } from 'react';
import api from '../../config/axios';
import { headersToSave } from '../../config/axios';
import "../../../src/client/scss/forgotpassword.scss";
import { userSetLoading } from "../../reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { verifyEmailPassword } from "../../scripts/helpers/validationScripts";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../client/images/itnoa-dark.png";
import ShowToast from "./Toast";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

const parseQueryString = (queryString) => {
    const params = {};
    const keyValuePairs = queryString.slice(1).split('&');
    keyValuePairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        params[key] = value.replace(/\+/g, ' '); // Replace '+' with space
    });
    return params;
};

const EmailVerification = () => {
    const dispatch = useDispatch();
    const [token, setToken] = useState({});
    const navigate = useNavigate();

    const verifyEmail = async (confirmation_token, config) => {

        try {

            const response = await api.get(`/api/v1/auth/confirmation?confirmation_token=${confirmation_token}&config=${config}`);

            ShowToast(response.data.message, 'success');

            const customObject = { "token-type": 'Bearer', 'config': 'default' };

            setToken({ ...response.data.data.token, ...customObject });
        } catch (error) {

            if (error.response) {
                if (error.response.status == '422') {
                    if (error.response.data.error.email) {

                        ShowToast(`email ${error.response.data.error.email[0]}`, 'error');
                    }
                    if (error.response.data.error.confirmation_token) {

                        ShowToast(`confirmation link ${error.response.data.error.confirmation_token[0]}`, 'error');
                    }
                }
            }
        }
    }

    useEffect(() => {

        const queryParams = parseQueryString(window.location.search);
        const confirmation_token = queryParams['confirmation_token'];
        const config = queryParams['config'];
        if (config && confirmation_token) {
            verifyEmail(confirmation_token, config);

        } else {

            window.location.href = '/login';
        }

    }, []);

    const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {

        headersToSave.forEach((header) => {
            // save header to local storage
            localStorage.removeItem(header);

        });

        dispatch(userSetLoading(true));
        try {
            const response = await api.put("api/v1/auth/password", values, { headers: token });

            ShowToast(response.data.message, 'success');

        } catch (error) {
            if (error.response.status == "422") {
                setErrors(error.response.data.error);
            }
            else {
                ShowToast(error.response.data.errors[0], 'error');
            }
        }
        finally {
            setSubmitting(false);
            dispatch(userSetLoading(false));
            resetForm();
        }
    };

    const goTo = () => {
        navigate('/login');
    }

    return (
        <div className="page-main">
            <ToastContainer />

            <div className="container password-update">
                <div className="header-hms"><img src={logo} alt="logo" /></div>


                <div className="forgot-panel">
                    <div className="forget-form">
                        <p className="page-title">Update Password</p>


                        <Formik
                            initialValues={verifyEmailPassword.initialValues}
                            validationSchema={verifyEmailPassword.schema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors }) => (
                                <Form>
                                    <div>
                                        <div className="form-input">
                                            <label htmlFor="password">Password</label>
                                            <Field
                                                type="password"
                                                id="password"
                                                name="password"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                className="invalid-feedback d-block"
                                                component="span"
                                            />
                                        </div>
                                        <div className="form-input">
                                            <label htmlFor="password_confirmation">Confirm password</label>
                                            <Field
                                                type="password"
                                                id="password_confirmation"
                                                name="password_confirmation"
                                            />
                                            <ErrorMessage
                                                name="password_confirmation"
                                                className="invalid-feedback d-block"
                                                component="span"
                                            />
                                        </div>
                                        <button className="mb-2 btn submitBtn" type="submit">
                                            Update Password
                                        </button>
                                        <Button type="button" component={Link} href="/login" className="btn-back">
                                            Login
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EmailVerification;