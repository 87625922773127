import React from 'react';
import { Link } from 'react-router-dom';
import './../../client/scss/NotFound.scss'; // Import your SCSS styles for the 404 page
import { Container, Row, Col } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Container className="not-found-container">
      <Row className="justify-content-center align-items-center">
        <Col xs={12} className="not-found-content text-center">
          <h1>404</h1>
          <p>Oops! The page you're looking for doesn't exist.</p>
          <Link to="/">Go back to homepage</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;