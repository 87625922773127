import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timer: '',
    data: [],
    response: {
        status: "",
        successMsg: "",
        errorMsg: "",
        msgState: false
    },
    updateBtn: '',
    openModal: false,
    isChecked: false,
    mapData: [],
    mapDepartmentsList: [],
    searchQuery: '',
    order: '',
    shiftModal: ''
}

const receptionSlice = createSlice({
    name: 'reception',
    initialState,
    reducers: {
        setTimer: (state, action) => {
            state.timer = action.payload;
        },
        setReceptionist: (state, action) => {
            state.data = action.payload;
        },
        setShiftPopup: (state, action) => {
            state.shiftModal = action.payload;
        },
        setMapData: (state, action) => {
            state.mapData = action.payload;
        },
        setResponse: (state, action) => {
            state.response = action.payload;
        },
        resetResponse: (state, action) => {
            state.response = {
                status: "",
                successMsg: "",
                errorMsg: "",
                msgState: false
            };
        },
        setAsynDepartments: (state, action) => {
            state.mapDepartmentsList = (action.payload);
        },
        addReceptionist: (state, action) => {
            state.data.push(action.payload)
        },
        setModal: (state, action) => {
            state.openModal = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    },
});

export const { setTimer, setReceptionist, setShiftPopup, setMapData, setResponse, addReceptionist, setModal, setIsChecked, resetResponse, setAsynDepartments, setSearchQuery, setOrder } = receptionSlice.actions;

export default receptionSlice.reducer;