

/**
 *  Gets value of params based on browser url 
 * @param {String} key - Key for required value 
 * @param {String}query - Query string 
 * @returns {Object || null} - Returns value or null if not found 
 */
function GetQueryParam(key, query) {
    const queryParams = query.split('&');

    for (const param of queryParams) {
        const [paramKey, paramValue] = param.split('=');
        if (paramKey === key) {
            return decodeURIComponent(paramValue);
        }
    }

    // Return a default value or handle the case when the parameter is not found
    return null;
}

export {
    GetQueryParam
}