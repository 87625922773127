import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    updateBtn: '',
    openModal: false,
    isChecked: false,
    mapDepartmentsList: [],
    searchQuery: '',
    order: '',
    updateDesignationValues: {
        designationName: '',
        idType: ''
    },
    designationListId: ''
}

const designationSlice = createSlice({
    name: 'designation',
    initialState,
    reducers: {
        setDesignation: (state, action) => {
            state.data = action.payload;
        },
        addDesignation: (state, action) => {
            state.data.push(action.payload)
        },
        updateDesignation: (state, action) => {
            state.data = action.payload;
        },
        setUpdateBtn: (state, action) => {
            state.updateBtn = action.payload;
        },
        setModal: (state, action) => {
            state.openModal = action.payload;
        },
        setAsynDepartments: (state, action) => {
            state.mapDepartmentsList = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setUpdateDesignationValues: (state, action) => {
            state.updateDesignationValues = action.payload;
        },
        setDesignatioListId: (state, action) => {
            state.designationListId = action.payload;
        }
    },
});

export const { setDesignation, addDesignation,
    updateDesignation, setUpdateBtn, setModal, setIsChecked, setAsynDepartments, setSearchQuery, setOrder, setDesignatioListId, setUpdateDesignationValues } = designationSlice.actions;

export default designationSlice.reducer;