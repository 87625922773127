import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roaster: [],
    departments: []
}

const dutyRoaster = createSlice({
    name: 'roaster',
    initialState,
    reducers: {
        setRoaster: (state, action) => {
            state.roaster = action.payload;
        },
        setDepartments: (state, action) => {
            state.departments = action.payload;
        }
    },
});

export const { setRoaster, setDepartments } = dutyRoaster.actions;

export default dutyRoaster.reducer;