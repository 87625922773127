import React, { useState, createContext } from "react";

export const SidebarMenuContext = createContext(null);
export const SidebarProvider = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [collapsedmob, setCollapsedMob] = useState(false);

    const handleMenuToggle = () => {
        if (window.screen.width >= 992) {
            setCollapsed(!collapsed);
        }
        else {
            if (collapsedmob) {
                setCollapsedMob(false);
            }
            else {
                setCollapsedMob(true);
            }
        }
    }

    return (
        <SidebarMenuContext.Provider value={{ collapsedmob, setCollapsedMob, collapsed, handleMenuToggle }}>
            {props.children}
        </SidebarMenuContext.Provider>
    )
}