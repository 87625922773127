import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    checkBox: false,
    modal: '',
    updateBtn: false,
    updateServiceValue: {
        serviceName: '',
        serviceDesc: '',
        serviceFee: '',
        serviceETA: '',
        type: ''
    },
    serviceListId: '',
    searchQuery: '',
    order: ''
}

const serviceSLice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setService: (state, action) => {
            state.data = action.payload;
        },
        addService: (state, action) => {
            state.data.push(action.payload)
        },
        setUpdateService: (state, action) => {
            state.data = action.payload;
        },
        setCheckBox: (state, action) => {
            state.checkBox = action.payload;
        },
        setModal: (state, action) => {
            state.modal = action.payload;
        },
        setUpdateBtn: (state, action) => {
            state.updateBtn = action.payload;
        },
        setServiceListId: (state, action) => {
            state.serviceListId = action.payload;
        },
        setupdateServiceValue: (state, action) => {
            state.updateServiceValue = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    },
});

export const { setService, addService, setUpdateService, setCheckBox, setModal, setUpdateBtn, setServiceListId, setupdateServiceValue, setSearchQuery, setOrder } = serviceSLice.actions;

export default serviceSLice.reducer;