import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    saveGraph: [],
    revenue: []
}

const graphSlice = createSlice({
    name: 'graph',
    initialState,
    reducers: {
        setSaveGraph: (state, action) => {
            state.saveGraph = action.payload;
        },
        setRevenueGraph: (state, action) => {
            state.revenue = action.payload;
        }
    }
});

export const { setSaveGraph, setRevenueGraph } = graphSlice.actions;

export default graphSlice.reducer;