import { Navigate } from "react-router-dom";
import { getSavedHeader } from "../../config/axios"

const ProtectedRoute = ({component}) => {
    const isAuthenticated = getSavedHeader('client') ? true : false;
    if(!isAuthenticated) {
        return <Navigate to="/login" />
    }
    return component;
}

export default ProtectedRoute;