import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    currentAppointment: [],
    date: '',
    userInfoData: '',
    appointmentId: '',
    visitId: '',
    apptVisitId: '',
    vitalSignId: '',
    medData: '',
    clinicalNotes: '',
    medicines: [],
    isChecked: false,
    appointmentModal: false,
    asyncDoctorList: [],
    asyncPatientList: [],
    asyncDepartmentList: [],
    doctorSlot: [],
    currentMedication: [],
    setinfo: false,
    appointmentData: ''
}

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        setAppointment: (state, action) => {
            state.data = action.payload;
        },
        addAppointment: (state, action) => {
            state.data.push(action.payload);
        },
        setCurrentAppointment: (state, action) => {
            state.currentAppointment = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfoData = action.payload;
        },
        setAppointmentId: (state, action) => {
            state.appointmentId = action.payload;
        },
        setVisitIdOnstart: (state, action) => {
            state.visitId = action.payload;
        },
        setApptVisitId: (state, action) => {
            state.apptVisitId = action.payload;
        },
        setVitalSignId: (state, action) => {
            state.vitalSignId = action.payload;
        },
        saveMedicalData: (state, action) => {
            state.medData = action.payload;
        },
        setClinicalNotes: (state, action) => {
            state.clinicalNotes = action.payload;
        },
        setMedicineSearch: (state, action) => {
            state.medicines = action.payload;
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
        },
        setAppointmentModal: (state, action) => {
            state.appointmentModal = action.payload;
        },
        setDoctors: (state, action) => {
            state.asyncDoctorList = action.payload;
        },
        setAsyncPatients: (state, action) => {
            state.asyncPatientList = action.payload;
        },
        setAsyncDepartments: (state, action) => {
            state.asyncDepartmentList = action.payload;
        },
        setDoctorSlot: (state, action) => {
            state.doctorSlot = action.payload;
        },
        getCurrentMedicationData: (state, action) => {
            state.currentMedication = action.payload;
        },
        showInfo: (state, action) => {
            state.setinfo = action.payload;
        },
        addMedicationData: (state, action) => {
            state.currentMedication.push(action.payload);
        },
        setAppointmentData: (state, action) => {
            state.appointmentData = action.payload;
        }
    },
});

export const { setAppointment, addAppointment, setCurrentAppointment, setDate, setUserInfo, setAppointmentId, setVisitIdOnstart, setApptVisitId, setVitalSignId, saveMedicalData, setClinicalNotes, setMedicineSearch,
    setIsChecked, setAppointmentModal, setDoctors, setAsyncPatients, setAsyncDepartments, setDoctorSlot, getCurrentMedicationData, showInfo, addMedicationData, setAppointmentData } = appointmentSlice.actions;

export default appointmentSlice.reducer;